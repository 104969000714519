import { store } from "..";
import { SendSocketIoRawMessage, notifyListeners } from "./Websocket";
import { _setGroup , _setActualGroup } from "../_redux/actions/websocket";


let user_id = null;
export function Init(socket, _user_id){
  user_id = _user_id;
  socket.on(Events.start, (data)=>{
    //console.log("AR Websock","AR started", data);
    //store.dispatch(_setGroup({group_id:data.group_id, my_uuid:user_id, stats:data.stats}));

    notifyListeners(Events.start, data);
  })
  socket.on(Events.end, (data)=>{
    console.log("AR Websock","AR ended", data);
    notifyListeners(Events.end, data);
  });

  socket.on(Events.posUpdate, (data)=>{
    //console.log("AR Websock","AR posUpdated", data?.pos);
    //store.dispatch(_setGroup({group_id:data.group_id, my_uuid:user_id, stats:data.stats}));
    notifyListeners(Events.posUpdate, data);
  })

  socket.on(Events.sceneUpdate, (data)=>{
    console.log("AR Websock","AR sceneUpdated", data);
    notifyListeners(Events.sceneUpdate, data);
  });
}



export function Start({group_id, user_id})
{
  SendSocketIoRawMessage(Events.start,{group_id, user_id})
}

export function End({group_id, user_id})
{
  //console.log(Events.shareSetListener,{group_id:group_id, listener_id:user_id, action:action?"set":"unset"});
  SendSocketIoRawMessage(Events.end,{group_id,user_id})
}


export function UpdatePosition({group_id, user_id, pos})
{

  //console.log("UpdatePosition", {group_id, user_id, pos})
  //if(group_id == null)
    //console.error("No group id", msg);

  SendSocketIoRawMessage(Events.posUpdate,{group_id, user_id, pos})
}

export function UpdateScene({group_id, user_id, delta})
{
  SendSocketIoRawMessage(Events.sceneUpdate,{group_id, user_id, delta})
}



export const Events = {
  start: 'ar_start',
  posUpdate: 'ar_pos_update',
  sceneUpdate: 'ar_scene_update',
  end: 'ar_end'
}